import React, { useContext } from 'react';
import { GeneDataContext  } from './hooks/GeneDataContext';

function Buttons(){

	const { setGeneScale , setFilterType, setXvar} = useContext(GeneDataContext);

	return(
		<>
			<div className='btn-header'>Geografisk skala</div>
	        <button onClick={() => setGeneScale('grid')} id='grid' className='btn btn-scale-up'>Kvadrater</button>
	        <button onClick={() => setGeneScale('realms')} id='realms' className='btn btn-scale-down'>Regioner</button>
	        <div className='btn-header'>Datafilter</div>
	        <button onClick={() => setFilterType('full')} id='full' className='btn btn-filter-up '>Alle data</button>
	        <button onClick={() => setFilterType('good')} id='good' className='btn btn-filter-down'>Filtrerede data</button>
	        <div className='btn-header'>Variable</div>
	        <button onClick={() => setXvar('spRichness')} id='spRichness' className='btn btn-pred-up'>Artsrigdom</button>
	        <button onClick={() => setXvar('meanTemp')} id='meanTemp' className='btn btn-pred-middle'>Årligt temperaturgennemsnit</button>
	        <button onClick={() => setXvar('npp')} id='npp' className='btn btn-pred-down'>Primærproduktion</button>
        </>
	);
}

export default Buttons;