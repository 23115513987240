import React, { useEffect, useRef, useContext } from "react";
import * as d3 from 'd3';
import useProj from './hooks/useProj';
import { handleMouseClick } from './hooks/handleMouseClick';
import { GeneDataContext  } from './hooks/GeneDataContext';
import { useWindowDimension } from './hooks/useWindowDimension';

function GeneMap () {
    const [windowWidth, windowHeight] = useWindowDimension();
    const { topoData, FilteredSet, colorScale } = useContext(GeneDataContext);
    //DEFINE CONTAINER AND PROJECTION
    const gRef = useRef();
    const projRef = useProj('#gene-map', '#gene-map');

    // CREATES THE PROJECTION AND RENDERS CHART
    useEffect(() => {
        if (topoData && FilteredSet) {
            d3.select('#GD-map').selectAll('*').remove();
            //DEFINE PATH AND RENDER MAP
            renderChart(topoData, FilteredSet, projRef.current, colorScale, gRef);
        }
    }, [topoData, FilteredSet, windowWidth, windowHeight, projRef, colorScale]);

    return (
        <g ref={gRef} id='GD-map'></g>
    );
}

export default GeneMap;

// MAP RENDERING FUNCTION
function renderChart (data, subset, proj, colorScale, container) {
    const path = d3.geoPath().projection(proj);
    const map = d3.select(container.current).append('g')
    const em = parseFloat(d3.select('#gene-map').style('font-size'))

    map.selectAll('.geoPaths')
        .data(data)
    .enter().append('path')
        .attr('d', path)
        .style('fill', 'rgba(255, 255, 255, 0)')
        .transition().duration(300).ease(d3.easePoly.exponent(5))
        .style('fill', d => subset.includes(d.properties['ID'])  ? colorScale(d.properties['GD']) : 'rgba(0,0,0,0.1)')
        .style('fill-opacity', 1)
        .attr('class', d => parseInt(d.properties['ID']) ? 'point' : 'feature');

    map.selectAll('path').on('click', function(event, c) {
                                                handleMouseClick(event, c);
                                            });

    //DRAW COLOR SCALE
    const contWidth = parseInt(window.getComputedStyle(d3.select('#gene-map').node()).width);
    const contHeight = parseInt(window.getComputedStyle(d3.select('#gene-map').node()).height);

    const rectWidth = d3.min([contWidth, contHeight])*0.05;
    
    d3.select('#map-legend').remove();
    const colLeg = d3.select('#legend-cont').append('g').attr('id', 'map-legend');

    colLeg.selectAll('.rect')
        .data(colorScale.range())
    .enter().append('rect')
        .each(function(d, i){
            d3.select(this)
            .attr('fill', d)
            .attr('x',  0)
            .attr('y',  - (i*rectWidth*1.1) )
            .attr('width', rectWidth)
            .attr('height', rectWidth )
    });

    const colDat = ( colorScale.hasOwnProperty('quantiles') ) ? colorScale.quantiles() : colorScale.domain();
    colDat.unshift(0);

    colLeg.selectAll('.txt')
        .data(colDat)
    .enter().append('text')
        .attr('class', 'axis')
        .style('fill', 'rgb(0,0,0)')
        .style('font-weight', 350)
        .each(function(d, i){
            d3.select(this)
            .attr('transform', function(d){
                //calculate x, y positions in pixels from percentage given above
                const tx = rectWidth + rectWidth/5
                const ty = -(i-1.2)*rectWidth*1.1
                //apply first rotation to each and then translation
                return 'translate(' + tx + ',' + ty + ')' + 'rotate(0)'
            })
            .text(d=>(precision(d) < 4) ? parseInt(d) : d.toFixed(4))
    });

    //LEGEND TITLE
    const legT = colLeg.append('text').attr('x', -0.8*em).attr('y', -colLeg.node().getBBox().height*0.94)

    legT.append('tspan').text('G').style('font-weight', 900)
            .append('tspan').text('enetisk ').style('font-weight', 300)
            .append('tspan').text('D').style('font-weight', 900)
            .append('tspan').text('iversitet').style('font-weight', 300)
            .append('tspan').attr('dx', '-7em').attr('dy', '1em').text('(').style('font-style', 'italic')
            .append('tspan').text('cytokrom b').style('font-style', 'italic')
            .append('tspan').text(')').style('font-style', 'normal')

    //LEGEND BACKGROUND
    const rectLeg = colLeg.node().getBBox()
    colLeg.insert('rect', ':first-child')
        .attr('x', -rectLeg.width*0.05 - 0.8*em)
        .attr('y', rectLeg.y)
        .attr('width', rectLeg.width + rectLeg.width*0.1)
        .attr('height', rectLeg.height)
        .attr('rx', '5')
        .attr('ry', '5')
        .style('fill', 'rgba(255, 255, 255, 0.8)');

    const extraPad = parseFloat(d3.select('.container').style('padding'));
    const lineHeight = parseFloat(d3.select('#gene-map').style('line-height'));
    colLeg.attr('transform', 'translate('  + (rectLeg.width*0.05 + 0.8*em) +',' + (contHeight - lineHeight - extraPad) + ')' );
        
};


function precision(a) {
    if (!isFinite(a)) return 0;
    var e = 1, p = 0;
    while (Math.round(a * e) / e !== a) { e *= 10; p++; }
    return p;
}


