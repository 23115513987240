import React, { useState, useEffect } from 'react';

export function useWindowDimension() {
    
    const wWidth = window.innerWidth;
    const wHeight = window.innerHeight;

    const [dimension, setDimension] = useState([
        wWidth,
        wHeight,
        ]);
    useEffect(() => {
        const debouncedResizeHandler = debounce(() => {
        
            if(window.innerWidth != wWidth){
                setDimension([window.innerWidth, window.innerHeight]);
            }

        }, 0); // SET DELAY FOR RERENDER
        
        window.addEventListener('resize', debouncedResizeHandler);
        
        return () => window.removeEventListener('resize', debouncedResizeHandler);
        
        }, []); 
    return dimension;
}

function debounce(fn, ms) {
    let timer;
    return _ => {
        clearTimeout(timer);
        timer = setTimeout(_ => {
            timer = null;
            fn.apply(this, arguments);
        }, ms);
    };
}