import * as d3 from 'd3'

export function handleMouseClick(event, c){ 
    handleMouseOut()

    const currentScale = d3.select('#scatter-plot')['_groups'][0][0]['__zoom'].k

    const em = parseFloat(d3.select('#gene-map').style('font-size'));
    //SELECT IN MAP
    const mapElement = d3.selectAll('#GD-map path')
            .filter(function(d) {return d.properties['ID'] === c.properties['ID']})
            .raise()
            .classed('selected-cell', d => parseInt(d.properties['ID']) ? true : false)
            .classed('selected-feature', d => parseInt(d.properties['ID']) ? false : true);

    //SELECT IN SCATTER
    const statElement = d3.selectAll('#scatter-g circle')
        .filter(function(d) {return d.properties.ID === c.properties.ID})
        .raise()
        .classed('selected-point', true);

    //DRAW LINES IN SCATTER
    const pointBbox = statElement.node().getBBox();
    const xAxisScatter = d3.select('#xAxis-scatter').node().getBBox();
    const yAxisScatter = d3.select('#yAxis-scatter').node().getBBox();

    const xTranStr = d3.select('#xAxis-scatter').attr('transform');
    const xTrans = xTranStr.substring(xTranStr.indexOf('(')+1, xTranStr.indexOf(')')).split(',');

    const yTranStr = d3.select('#yAxis-scatter').attr('transform');
    const yTrans = yTranStr.substring(yTranStr.indexOf('(')+1, yTranStr.indexOf(')')).split(',');

    const scatterLines = d3.select('#scatter-lines')
    
    scatterLines.append('line')
        .attr('x1', yAxisScatter.x + parseFloat(yTrans[0]) + yAxisScatter.width)
        .attr('x2', pointBbox.x + pointBbox.width/2)
        .attr('y1', pointBbox.y + pointBbox.height/2)
        .attr('y2', pointBbox.y + pointBbox.height/2)
        .attr('stroke-width', 1.5/currentScale)
        .attr('stroke-dasharray', 4/currentScale + ',' + 2/currentScale)
        .attr('clip-path', 'url(#clip)')
        .transition().ease(d3.easeLinear).duration(200)
        .style('opacity', 1);

    scatterLines.append('line')
        .attr('x1', pointBbox.x + pointBbox.width/2)
        .attr('x2', pointBbox.x + pointBbox.width/2)
        .attr('y1', xAxisScatter.y + xAxisScatter.y + parseFloat(xTrans[1]) )
        .attr('y2', pointBbox.y + pointBbox.height/2)
        .attr('stroke-width', 1.5/currentScale)
        .attr('stroke-dasharray', 4/currentScale + ',' + 2/currentScale)
        .attr('clip-path', 'url(#clip)')
        .transition().ease(d3.easeLinear).duration(200)
        .style('opacity', 1);

    //SHOW TOOLTIP
    //const [x, y] = d3.pointer(event);
    const legCont = d3.select('#map-legend').node().getBoundingClientRect();

    const tooltipMap = d3.select('#gene-map').append('foreignObject')
        .attr('id', 'tip-cont')
        .attr('width', legCont.width)
        .attr('height', '4.5em')
        .attr('x', 0 )
        .attr('y', 2*em)
        .append('xhtml:div').attr('class', 'tooltip');

    tooltipMap.append('span').text('GD: ').style('font-weight', 900)
    tooltipMap.append('span').text(c.properties.GD.toFixed(4))
    tooltipMap.append('br')
    tooltipMap.append('span').text('ID: ').style('font-size', 0.9*em + 'px')
    tooltipMap.append('span').text( c.properties.ID );

    tooltipMap.transition().ease(d3.easeLinear).duration(200)
        .style('opacity', 1);

    event.stopPropagation();

}

export function handleMouseOut(event, d){
	 d3.selectAll('#GD-map path').classed('selected-cell', false);
     d3.selectAll('#GD-map path').classed('selected-feature', false);
	 d3.selectAll('#scatter-g circle').classed('selected-point', false);
     d3.select('#tip-cont').remove();
     d3.selectAll('#scatter-lines line').remove();
};
