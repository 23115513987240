import React, { useEffect, useState, useRef, useContext } from 'react';
import * as topojson from "topojson-client";
import * as d3 from 'd3';
import {  geoPath } from "d3-geo";
import useProj from './hooks/useProj'
import {useWindowDimension} from './hooks/useWindowDimension';

function BaseMap() {
    const [windowWidth, windowHeight] = useWindowDimension();
    const [topoData, setTopoData] = useState(0)
    //FETCH WORLD DATA
    useEffect(() => {
        fetch('/data/world_110m.topojson')
            .then(response => {
                response.json().then(d => {setTopoData(d)})
            })
        }, [])

    //DEFINE CONTAINER AND PROJECTION
    const gRef = useRef();
    const projRef = useProj('#gene-map', '#gene-map')
    
    // CREATES THE PROJECTION AND RENDERS CHART
    useEffect(() => {
        if (topoData) {
            d3.select('#base-map').selectAll('*').remove();
            //RENDER MAP
            renderChart(topoData, projRef.current);
        }
    }, [topoData, windowWidth, windowHeight]);

    // RENDER THE MAP
    const renderChart = (data, proj) => {
        //DEFINE PATH
        const path = d3.geoPath().projection(projRef.current);
        //DEFINE AND DRAW GRATICULE
        const line = d3.line()
                                        .x(d=>proj([d.x, d.y])[0])
                                        .y(d=>proj([d.x, d.y])[1])

        const gratLines = Array(7).fill().map((d, i) => 80 - i*20).map(d=>{
            return [{x: -180, y: d},
                            {x: 180, y: d}
                            ];
        });

        d3.select(gRef.current).selectAll('gratLines')
            .data(gratLines)
        .enter().append('path')
            .attr('d', line)
            .attr('stroke', 'rgb(160, 160, 160)')
            .attr('stroke-width', '0.03em')
            .attr('stroke-dasharray', '0.2em,0.2em');

        //DRAW LAND
        d3.select(gRef.current)
            .datum(topojson.mesh(data, data.objects.world_110m, function (a,b) {return a === b; }))
            .append('path')
            .attr('d', path)
            .style('stroke', null)
            .style('stroke-width', 0.2)
            .style('fill', 'rgb(230, 230, 230)')
        //DRAW COAST
        d3.select('#globe-stroke').remove();
        d3.select('#coast-cont').append('g').attr('id', 'globe-stroke')
            .datum(topojson.mesh(data, data.objects.world_110m, function (a,b) {return a === b; }))
            .append('path')
            .attr('d', path)
            .style('stroke', 'rgb(0, 0, 0)')
            .style('stroke-width', 0.2)
            .style('fill', 'none')


        };

    return (
        <g ref={gRef} id='base-map'></g>
    );
};

export default BaseMap;
