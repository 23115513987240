import React, { useRef, useEffect } from "react";
import * as d3 from 'd3'
import {geoCylindricalEqualArea} from "d3-geo-projection"
import {  geoPath } from "d3-geo";
import {useWindowDimension} from './useWindowDimension';

function  useProj(widthContainerID, heightContainerID) {
    const [windowWidth, windowHeight] = useWindowDimension();
    //DEFINE CONTAINER, PROJECTION AND GRATICULE
    const gRef = useRef();
    const projRefInit = useRef(geoCylindricalEqualArea().scale(1).translate([0,0])
                                        .precision(0.1)
                                        .rotate( [0, 0, 0])
                                        .parallel(30)
                                    );
    const projRef = useRef(geoCylindricalEqualArea().scale(1).translate([0,0])
                                        .precision(0.1)
                                        .rotate( [0, 0, 0])
                                        .parallel(30)
                                    );
    const gratRef = useRef(
        d3.geoGraticule().extent([[-180, -90], [180, 90]])
    );

    useEffect(() => {
        // GET CURRENT WIDTH/HEIGHT OF MAP CONTAINER
        const width = d3.select(widthContainerID).node().getBoundingClientRect().width;
        const height = d3.select(heightContainerID).node().getBoundingClientRect().height;
        // GET INITIAL DIMENSIONS OF BASEMAP'S GRATICULE AND CALCULATE SCALE AND TRANSLATE; EVERY PROJECTION HAS INITIAL DIMENSIONS CENTERED AROUND 0 (IN SCREEN PIXEL COORDINATES)
        const path = d3.geoPath().projection(projRefInit.current);
        const gratOutlineFeature = {
            type: 'Feature',
            properties: {},
            geometry: {
                type: gratRef.current.outline().type,
                coordinates: gratRef.current.outline().coordinates
                }
            };
        const initBox = path.bounds(gratOutlineFeature)
        const boxWidth  = initBox[1][0] - initBox[0][0];
        const boxHeight = initBox[1][1] - initBox[0][1];
        const s = 0.95 / Math.max(boxWidth / width,  boxHeight / height);
        const t = [ (width - s*(2*initBox[0][0] + boxWidth )) / 2, (height - s*(2*initBox[0][1] + boxHeight )) / 2.05];
        //REDEFINE PROJECTION
        projRef.current.scale(s).translate(t);
        projRef.current.dims = [boxWidth, boxHeight];
    },[windowWidth, windowHeight]);
    return projRef
}

export default useProj;