import './home.css';
import cmecLogo from '../img/cmec_new.png';
import BaseMap from '../components/BaseMap';
import GeneMap from '../components/GeneMap';
import BarPlot from '../components/BarPlot';
import Buttons from '../components/Buttons';
import StatsPlot from '../components/StatsPlot';
import { useContext, useEffect } from 'react';
import ΖoomPan from '../components/ΖoomPan';
import GeneDataContextProvider from '../components/hooks/GeneDataContext';
import * as d3 from 'd3'
import { handleMouseOut } from '../components/hooks/handleMouseClick';
import { useWindowDimension } from '../components/hooks/useWindowDimension';
import { Link } from 'react-router-dom';
import "@fontsource/mukta";

function Home() {
    
    const [windowWidth, windowHeight] = useWindowDimension();
    
    //ADD EVENT TO CONTAINER
    d3.select('body').on('click', handleMouseOut)
    //REMOVE TOOLTIPS ON RESIZE
    useEffect ( () => {
        handleMouseOut()
    }, [windowWidth, windowHeight])


    return (
        <GeneDataContextProvider>
            <div id='home'>
                < div className='title-cont'>
                    <span className='title'> Genetisk diversitet </span>
                    <span className='subtitle'> Undersøg den genetiske diversitet for pattedyr på global skala </span>
                </div>
                < div className='about-cont'>
                    <Link to='/about'>Om siden</Link>
                    <img src={cmecLogo} alt='logo' className='.img-crisp'/>
                </div>
                <div className='map-container container'>
                    <div className='main-map'>
                        <svg id='gene-map'>
                            <ΖoomPan />
                            <g id='gene-map-cont'>
                                <BaseMap />
                                <GeneMap />
                                <g id='coast-cont'></g>
                            </g>
                            <g id='legend-cont'></g>
                        </svg>
                    </div>
                </div>
                <div className='latbands container'>
                    <svg id='bar-plot'>
                        <BarPlot />
                    </svg> 
                </div> 
                <div className='data-filter container'>
                    <Buttons/>
                </div>
                <div className='stats-plot container'>
                    <svg id='scatter-plot'>
                        <StatsPlot />
                    </svg> 
                </div>
                < div className='copyright'>
                    <span> © 2024 Center for Makroøkologi, Evolution og Klima, Københavns Universitet </span>
                </div>
            </div>
        </GeneDataContextProvider>
    );
}

export default Home;