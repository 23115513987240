import cmecLogo from '../img/cmec_new.png';
import novoLogo from '../img/Novo_Nordisk_Foundation_logo.png';
import fadbLogo from '../img/FaDB-logo.png';
import './about.css';
import { Link } from 'react-router-dom';

function About() {

	return (
		<div id='about'>
			<div className='home-link'>
				<Link to='/'>Tilbage</Link>
			</div>
			<div className='text-description'>
				<div>
					<span style={{ fontWeight: 800 }}>Formål</span>
					<br/>
					<span>
						Formålet med denne side er, at gymnasieelever i biologi og bioteknologi kan undersøge den genetiske diversitet for pattedyr på global skala. Genetisk diversitet er – på lige fod med arter og økosystemer – en essentiel del af Jordens biodiversitet, som er afgørende at måle, forstå og beskytte.
					</span>
					<br/>
					<span>Tilhørende undervisningsmaterialer og arbejdsspørgsmål findes <a href='https://biodiversitetigymnasiet.dk/' target='_blank'>her</a>.
					</span>
					<br/><br/>
					<span style={{ fontWeight: 800 }}>Forskningsbaseret undervisning</span>
					<br/>
					<span>
						Her på siden kan eleverne undersøge et datasæt, der viser den genetiske diversitet for pattedyr på en global skala. Data er samlet ved at hente DNA-sekvenser fra <span style={{ fontStyle: 'italic' }}>cytokrom b</span>-genet hos 1.690 arter af landlevende pattedyr (flere end 24.000 DNA-sekvenser i alt) fra enorme genetiske databaser, og derefter kombinere sekvenserne med geografiske koordinater fra de steder på Jorden, hvor DNA-prøverne oprindeligt blev taget. Herefter er den gennemsnitlige genetiske diversitet blevet udregnet både for Jordens zoogeografiske regioner samt for kvadrater med en størrelse på 385,9 x 385,9 km.
					</span>
					<br/>
					<span>
						Den interaktive side bygger på et datasæt fra forskningsartiklen ’Evolutionary history and past climate change shape the distribution of genetic diversity in terrestrial mammals’, som er udgivet i tidsskriftet Nature Communications i 2020. Det videnskabelige studie er udført af forskerne Spyros Theodoridis, Damien A. Fordham, Stuart C. Brown, Sen Li, Carsten Rahbek og David Nogués-Bravo.
					</span>
					<br/>
					<span>
						Du kan finde forskningsartiklen <a href='https://www.nature.com/articles/s41467-020-16449-5' target='_blank'>her</a>.
					</span>
					<br/><br/>
					<span style={{ fontWeight: 800 }}>Om os</span>
					<br/>
					<span>
					Siden er udviklet af Spyros Theodoridis, Emma Emilie Andersen, Karsten Elmose Vad, David Nogués-Bravo og Carsten Rahbek fra Center for Makroøkologi, Evolution og Klima (CMEC) på Københavns Universitet. 
					</span>
					<br/>
					<span>
					Undervisningsmaterialet er en del af projektet <a href='https://biodiversitetigymnasiet.dk/' target='_blank'>’Biodiversitet i gymnasiet’</a>, som er et samarbejde mellem CMEC og Foreningen af Danske Biologer (FaDB). 
					</span>
					<br/>
					<span>
					Alle materialer er udviklet i tæt samarbejde mellem biodiversitetsforskere på CMEC og gymnasielærere i biologi og bioteknologi fra hele landet. Projektet er støttet af Novo Nordisk Fonden.
					</span>
				</div>
			</div>
			<div className='logos-about'>	
				<img src={cmecLogo} className='cmec'/>
				<img src={fadbLogo} className='fadb' />
				<img src={novoLogo} className='novo' />
			</div>
		</div>
		);

};

export default About;