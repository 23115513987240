import React, { useEffect, useRef, useContext } from "react";
import * as d3 from 'd3';
import { GeneDataContext  } from './hooks/GeneDataContext';
import { handleMouseClick } from './hooks/handleMouseClick';
import {useWindowDimension} from './hooks/useWindowDimension';
import useProj from './hooks/useProj';

function StatsPlot () {
	const [windowWidth, windowHeight] = useWindowDimension();
	const { Xvar, topoData, FilteredSet, colorScale } = useContext(GeneDataContext);
	const gRef = useRef();
	const projRef = useProj('#gene-map', '#gene-map');

	useEffect(() => {
		if(Xvar && topoData && FilteredSet){
			d3.select('#scatter-g').selectAll('*').remove();
			['spRichness', 'meanTemp', 'npp'].forEach(d => d == Xvar ?  d3.select('#' + d).classed('btn-active', true) : d3.select('#' + d).classed('btn-active', false));
			plotSactter(topoData, FilteredSet, Xvar, colorScale, gRef, projRef);
		}
	}, [Xvar, topoData, FilteredSet, windowWidth, windowHeight]);

	return (
		<g ref={gRef} id='scatter-g'></g>
	);

}

export default StatsPlot;

function plotSactter(data, subset, predictor, colorScale, container, proj){
	const SVGCont = d3.select('#scatter-plot');
	const width = SVGCont.node().getBoundingClientRect().width;
	const height = SVGCont.node().getBoundingClientRect().height;
	const em = parseFloat(SVGCont.style('font-size'))

	const margin =  {'left': 0.16*width + em, 'right': 0.06*width, 'top': 0.07*height, 'bottom': 0.15*height + 2*em}

	const xg = d3.select(container.current).append('g').attr('id', 'xAxis-scatter');
	const yg = d3.select(container.current).append('g').attr('id', 'yAxis-scatter');
	const dPoints = d3.select(container.current).append('g');
	const regLine = d3.select(container.current).append('g');
	const pointLines = d3.select(container.current).append('g').attr('id', 'scatter-lines');

	// ADD X AXIS
	const xArr = data.map(d=> d.properties[predictor] )
	const xExtent = d3.extent(xArr )
    const xStd = d3.deviation(xArr )
	const x = d3.scaleLinear()
						.domain([xExtent[0] - xStd/5, xExtent[1] + xStd/5])
						.range([ margin.left, width-margin.right]);
  	
	const xAxis = (g, x) => g.attr('transform', 'translate(0,' + (height - margin.bottom + 5) + ')')
  		.attr('class', 'axis')
    	.call(d3.axisBottom(x).ticks(5).tickSizeOuter(0).tickFormat(d3.format('~')))
    	.selectAll('text')
    	.attr('transform', 'translate(0,0)rotate(-25)')
    	.style('text-anchor', 'end');

    // ADD Y AXIS
    const yArr = data.map(d=>d.properties['GD']);
    const yExtent = d3.extent(yArr );
    const yStd = d3.deviation(yArr );
	const y = d3.scaleLinear()
						.domain([yExtent[0] - yStd/5, yExtent[1] + yStd/3])
						.range([ height - margin.bottom, margin.top]);
  	
	const yAxis  = (g, y) => g.attr('transform', 'translate(' + (margin.left - 1) + ',' + '0)')
		.attr('class', 'axis')
		.call(d3.axisLeft(y).ticks(5).tickSizeOuter(0).tickFormat(d3.format('~')))
		.selectAll('text')
    	.each(function(d){
		    if(!d3.range(0, 0.2, 0.01).includes(d)) d3.select(this).remove();
		});

	// DEFINE CLIP PATH
    d3.select('#clip').remove();
	const clipP = SVGCont.append('defs').append('clipPath').attr('id', 'clip')
	  .append('rect')
	  	.attr('x', x.range()[0])
	  	.attr('y', y.range()[1])
	    .attr('width',  x.range()[1] -  x.range()[0])
	    .attr('height', y.range()[0] -  y.range()[1] + 4);

	//SET TRANSFORM OF PLOT
    const zoom = d3.zoom()
        .on("zoom", scatterZoom);

  	function scatterZoom(e){
    	const zx = e.transform.rescaleX(x).interpolate(d3.interpolateRound);
    	const zy = e.transform.rescaleY(y).interpolate(d3.interpolateRound);
    	xg.call(xAxis, zx)
    	yg.call(yAxis, zy)

    	dPoints.attr('transform', e.transform);
    	dPoints.selectAll('circle').attr('r', (0.33*em)/(e.transform.k**0.8)).style('stroke-width', 0.05*em/(e.transform.k));

    	regLine.attr('transform', e.transform);
    	regLine.select('line').style('stroke-width', 2/e.transform.k);
    	
    	pointLines.attr('transform', e.transform);
    	pointLines.selectAll('line').attr('stroke-width', 1.5/e.transform.k).attr('stroke-dasharray', 4/e.transform.k + ',' + 2/e.transform.k);
    	
    	//REVERSE TRANSFORM THE CLIP OBJECT
    	clipP.attr('transform', 'scale(' + 1/e.transform.k + ')')
        	.attr('x', x.range()[0] - e.transform.x) 
        	.attr('y', y.range()[1] - e.transform.y);
	};

	function initZoom() {
        SVGCont.call(zoom.transform, d3.zoomIdentity).call(zoom.scaleExtent([1, 12]).extent([[margin.left, margin.top], [width-margin.right, height-margin.bottom]]).translateExtent([[margin.left, margin.top], [width-margin.right, height-margin.bottom]])    )
	}

	initZoom()

    //ADD X TITLE
    const xTitles = {'spRichness': 'Antal arter', 'meanTemp': '°C', 'npp': 'kg carbon / m² / år'};
    const xAxisHeight = xg.node().getBBox().height;
    const xTranStr = xg.attr('transform');
    const xTrans = xTranStr.substring(xTranStr.indexOf('(')+1, xTranStr.indexOf(')')).split(',');

    d3.select(container.current).append('text')
        .text(xTitles[predictor])
        .attr('x', x.range()[0] + (x.range()[1] - x.range()[0])/2.3)
        .attr('y', parseFloat(xTrans[1]) + xAxisHeight + 0.07*height)
        .style('text-anchor', 'middle');

    //ADD Y TITLE
    const yAxisWidth = yg.node().getBBox().width;
    
    d3.select(container.current).append('g').append('text')
        .text('Genetisk diversitet (')
        .attr('transform', 'translate('+ (margin.left-yAxisWidth- 0.03*width) + ',' + (y.range()[0] - (y.range()[0] - y.range()[1])/2.5) + ')rotate(-90)')
        .style('text-anchor', 'middle')
        .append('tspan').text('cyt b').style('font-style', 'italic')
        .append('tspan').text(')').style('font-style', 'normal');

    //DRAW POINTS
    dPoints.selectAll('.points')
    	.data(data)
    	.enter()
    	.append('circle')
    	.attr('cx', d=>x(d.properties[predictor]) )
    	.attr('cy', d=>y(d.properties['GD'])  )
    	.attr('r', 0.33*em)
    	.style('fill', d => subset.includes(d.properties['ID']) ? colorScale(d.properties['GD']) : 'rgba(0,0,0,0.05)')
    	.attr('class', d => subset.includes(d.properties['ID']) ? 'point' : 'point-background' )
    	.attr('clip-path', 'url(#clip)')
    	.each(function(d){
		    if(subset.includes(d.properties['ID'])) d3.select(this).raise();
		})
		.on('click', function(event, c){
			handleMouseClick(event, c);
			centerMap(event, c, proj);
		} );

	//DRAW REGRESSION LINE
	const xReg = data.filter(d=> subset.includes(d.properties['ID'])).map(d=> d.properties[predictor] )
	const yReg = data.filter(d=> subset.includes(d.properties['ID'])).map(d=>d.properties['GD']);
	const regresPar = linearRegression(yReg, xReg)
	regLine.append('line')
		.attr('clip-path', 'url(#clip)')
        .attr('x1', x(d3.min(xArr)))
        .attr('x2', x(d3.max(xArr)))
        .attr('y1', y(regresPar.intercept + regresPar.slope*d3.min(xArr)))
        .attr('y2', y(regresPar.intercept + regresPar.slope*d3.max(xArr)))
   		.style('stroke-width', 2)
        .attr('class', 'regression-line');

    //SHOW R (SQUARED)
    d3.select(container.current).append('text')
    	.attr('x', width*0.04)
    	.attr('y', height - 0.02*height)
    	.text('r²').style('font-style', 'italic').style('font-weight', 700)
    	.append('tspan').text( ' = ' + regresPar.r2.toFixed(2)).style('font-style', 'normal');

};

function linearRegression(y,x){
        var lr = {};
        var n = y.length;
        var sum_x = 0;
        var sum_y = 0;
        var sum_xy = 0;
        var sum_xx = 0;
        var sum_yy = 0;

        for (var i = 0; i < y.length; i++) {

            sum_x += x[i];
            sum_y += y[i];
            sum_xy += (x[i]*y[i]);
            sum_xx += (x[i]*x[i]);
            sum_yy += (y[i]*y[i]);
        } 

        lr['slope'] = (n * sum_xy - sum_x * sum_y) / (n*sum_xx - sum_x * sum_x);
        lr['intercept'] = (sum_y - lr.slope * sum_x)/n;
        lr['r2'] = Math.pow((n*sum_xy - sum_x*sum_y)/Math.sqrt((n*sum_xx-sum_x*sum_x)*(n*sum_yy-sum_y*sum_y)),2);

        return lr;
}

function centerMap(event, c, proj){
    const mapWidth = proj.current.dims[0]*proj.current.scale();
    const mapHeight = proj.current.dims[1]*proj.current.scale();
    const bboxCont = d3.select('#gene-map').node().getBoundingClientRect();
    const mapX = proj.current.translate()[0] - mapWidth/2;
    const mapY = proj.current.translate()[1] - mapHeight/2;
    const topLeft = [mapX - mapWidth/10, mapY - mapHeight/10];
    const bottomRight = [mapX + mapWidth*1.1, mapY + mapHeight*1.1];
	//SELECT IN MAP
    const mapElement = d3.selectAll('#GD-map path')
            .filter(function(d) {return d.properties['ID'] === c.properties['ID']});

    //GET CURRENT TRANSFORM
    const t_now = d3.zoomTransform(d3.select('#gene-map').node());
    //GET SELECTED ELEMENT POSITION
    const meBbox = mapElement.node().getBBox()
    //CALCULATE TRANSLATE VALUES
    const xZ = -(meBbox.x + meBbox.width/2)*t_now.k + bboxCont.width / 2 ;
    const yZ = -(meBbox.y + meBbox.height/2)*t_now.k + bboxCont.height / 2;

    const clampNumber = (num, a, b) => Math.max(Math.min(num, Math.max(a, b)), Math.min(a, b));
    const xClamp = [Math.abs(topLeft[0]*t_now.k) - Math.abs(topLeft[0]) , - bottomRight[0]*t_now.k + Math.abs(bottomRight[0])  ]
    const yClamp = [Math.abs(topLeft[1]*t_now.k) - Math.abs(topLeft[1]),  -bottomRight[1]*t_now.k + Math.abs(bottomRight[1])]

    const xZcl = clampNumber(xZ, xClamp[0], xClamp[1]);
    const yZcl = clampNumber(yZ, yClamp[0], yClamp[1]);
    
    //TRANSFORM TO CENTER THE POINT
    d3.select('#gene-map-cont').transition().ease(d3.easeQuadOut).duration(800).attr('transform', 'translate(' +  xZcl + ','  + yZcl + ')scale(' + t_now.k + ')')
    d3.select('#gene-map')['_groups'][0][0]['__zoom'] = d3.zoomIdentity.translate(xZcl, yZcl).scale(t_now.k)

}
