import React, { createContext, useState, useEffect, useMemo } from 'react';
import * as topojson from "topojson-client";
import * as d3 from 'd3'
//CREATE CONTEXT FOR PROVIDING DATA TO APP COMPONENTS
export const GeneDataContext = createContext();

function GeneDataContextProvider({ children }) {
     //DEFINE SCALE (GRID AND ZOOGEOGRAPHIC REALMS) PARAMETERS
    const [GeneScale, setGeneScale] = useState('grid');
    //DEFINE FILTER (FULL AND GOOD DATA) PARAMETERS
    const [FilterType, setFilterType] = useState('full');
    //DEFINE THE SUBSET
    const [FilteredSet, setFilteredSet] = useState(null);
    //DEFINE THE DATASET
    const [topoData, setTopoData] = useState(null);
    //DEFINE THE PREDICTOR
    const [Xvar, setXvar] = useState('spRichness');
    //DEFINE COLOR SCALE DOMAIN
    const [colorScale, setColorScale] = useState(() => {});
    //FETCH GENETIC DIVERSITY DATA; SET INITIAL SUBSET (FULL)
    useEffect(() => {
        if (GeneScale){
            //SELECT SCALE BUTTONS AND DEACTIVATE THEM ACCORDING TO SCALE
            const buttonList = d3.select('.data-filter').selectAll('button')['_groups'][0];
            buttonList.forEach(d => d.id == GeneScale || d.id == FilterType || d.id==Xvar ?  d3.select('#' + d.id).classed('btn-active', true) : d3.select('#' + d.id).classed('btn-active', false));
            if (GeneScale == 'realms'){
                buttonList.forEach(d=>['full', 'good'].includes( d.id ) ?  d3.select('#' + d.id).classed('btn-disabled', true).node().disabled = true: null);
            }else{
                buttonList.forEach(d=>['full', 'good'].includes( d.id ) ?  d3.select('#' + d.id).classed('btn-disabled', false).node().disabled = false: null);
            };
            //FETCH DATA
            fetch('/data/GD_' + GeneScale+ '.topojson')
            .then(response => {
                response.json()
                    .then(d => topojson.feature(d, d.objects['GD_' + GeneScale]).features)
                    .then(d => {setTopoData( d); return d})
                    .then(d=> {
                        const dataSubset =  filterData(FilterType, d);
                        setFilteredSet(dataSubset);
                        //GET THE DATA QUANTILES AND SET COLOR DOMAIN
                        const plotVar = d.map(f=>f.properties['GD'])
                        const quantData = [0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875, 0.95].map(q => d3.quantile(plotVar, q))
                        quantData[0] = (quantData[0] == 0) ? quantData[1]/2 : quantData[0];
                        const colScl = d3.scaleThreshold()
                            .domain(quantData)
                            .range(['rgb(13, 63, 50)', 'rgb(29, 108, 88)', 'rgb(172, 207, 159)', 'rgb(247, 222, 149)', 'rgb(240, 202, 86)', 'rgb(207, 156, 0)', 'rgb(201, 14, 41)', 'rgb(120, 28, 49)']);
                        setColorScale(() => colScl)
                    })
                })
        }
    }, [GeneScale]);

    //DEFINE  DATA SUBSET BASED ON FILTER TYPE
    useMemo(() => {
        if (FilterType && topoData){
            ['full', 'good'].forEach(d => d == FilterType ?  d3.select('#' + d).classed('btn-active', true) : d3.select('#' + d).classed('btn-active', false));

            const dataSubset =  filterData(FilterType, topoData);
            setFilteredSet(dataSubset);
        }
    },[FilterType])

    return (
        <GeneDataContext.Provider value={{ GeneScale, setGeneScale,
                                                                            FilterType, setFilterType, 
                                                                            Xvar, setXvar,
                                                                            topoData, FilteredSet,
                                                                            colorScale }}>
        {children}
        </GeneDataContext.Provider>
    );
}

export default GeneDataContextProvider;

function filterData(filter, data){
    const subset = [];
    data.forEach(function(d){
        if(filter == 'full'){
            subset.push(d['properties']['ID']);
        }else if( filter == 'good'){
             if (d['properties']['taxCover']>= 0.07 | d['properties']['gdSeqs']>= 55){
                subset.push(d['properties']['ID']);
            }
        }
    })
    return subset
}