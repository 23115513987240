import * as d3 from 'd3';
import React, { useEffect } from 'react';
import useProj from './hooks/useProj';
import {useWindowDimension} from './hooks/useWindowDimension';

function  ΖoomPan(){
    const [windowWidth, windowHeight] = useWindowDimension();
    //CALCULATE MAP EXTENT TO LIMIT PAN
    const projRef = useProj('#gene-map', '#gene-map');

     useEffect( () => {
            if(projRef){

                const t0 = d3.zoomTransform(d3.select('#gene-map').node());//INITIAL TRANSFORM
                const mapWidth = projRef.current.dims[0]*projRef.current.scale();
                const mapHeight = projRef.current.dims[1]*projRef.current.scale();
                const mapX = projRef.current.translate()[0] - mapWidth/2;
                const mapY = projRef.current.translate()[1] - mapHeight/2;
                const topLeft = [mapX - mapWidth/10, mapY - mapHeight/10];
                const bottomRight = [mapX + mapWidth*1.1, mapY + mapHeight*1.1];

                let zoom = d3.zoom()
                    .on('zoom', handleZoom);

                function handleZoom(e) {
                    d3.select('#gene-map-cont')
                        .transition().ease(d3.easeLinear).duration(100)
                            .attr('transform', e.transform);                
                }

                function initZoom(t0) {
                    d3.select('#gene-map').call(zoom.transform, t0)
                        .call(zoom.scaleExtent([0.9, 5]).translateExtent([topLeft, bottomRight])); //ADD .extent([topLeft, bottomRight]) TO PREVENT INITIAL PANNING
                }

                initZoom();
            }
        }, [windowWidth, windowHeight, projRef])
}

export default ΖoomPan;