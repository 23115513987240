import React, { useEffect, useState, useRef, useContext } from 'react';
import useProj from './hooks/useProj'
import {useWindowDimension} from './hooks/useWindowDimension';
import { GeneDataContext  } from './hooks/GeneDataContext';
import * as d3 from 'd3'

function BarPlot () {
    const [windowWidth, windowHeight] = useWindowDimension();
	const gRef = useRef();
	const projRef = useProj('#gene-map', '#bar-plot')
	const { colorScale, GeneScale, FilterType } = useContext(GeneDataContext);

	const [latData, setLatdata] = useState(null);

	useEffect(() => {
			d3.csv('/data/GD_latbands.csv')
				.then(function(d) {
					setLatdata(d)
				} )
	}, []);

	useEffect(() => {
		if (latData && colorScale && GeneScale && FilterType ){
			d3.select('#lat-plot').selectAll('*').remove()
			plotBar(latData, projRef.current, colorScale, gRef)
			if (FilterType == 'good' && GeneScale == 'grid'){
				d3.select('#lat-plot').append('rect').attr('x', 0).attr('y', 0)
					.attr('width', '100%').attr('height', '100%')
					.style('fill', 'rgba(255, 255, 255, 0.7)')
					.style('stroke', null);
			}
		}
	}, [latData, windowWidth, windowHeight, colorScale, GeneScale, FilterType]);


	return (
		<g ref={gRef} id='lat-plot'></g>
	);
}

export default BarPlot;

function plotBar(data, proj, colorScale, container){
	const width = d3.select('#bar-plot').node().getBoundingClientRect().width;
	const height = d3.select('#bar-plot').node().getBoundingClientRect().height;
	const em = parseFloat(d3.select('#bar-plot').style('font-size'))

	const margin =  {'left': 0.19*width + em, 'right': 0.15*width, 'top': 0, 'bottom': height - proj([0, -50])[1]};
	
	// ADD X TICKS
	const x = d3.scaleLinear()
		.domain([0.003, 0.0215])
		.range([ margin.left, width-margin.right]);
  	
	d3.select(container.current).append('g').attr('id', 'xAxis')
  		.attr('transform', 'translate(0,' + (height - margin.bottom + 2) + ')')
    	.call(d3.axisBottom(x).ticks(5).tickSizeOuter(0).tickFormat(d3.format('~')))
    	.attr('class', 'axis')
    	.selectAll('text')
    	//.attr('transform', 'translate(-10,0)rotate(-45)')
    	.each(function(d,i){
		    if(![1,3].includes(i)) d3.select(this).remove();
		});

	//ADD X TITLE
    const xAxisHeight = d3.select('#xAxis').node().getBBox().height;
    const xTranStr = d3.select('#xAxis').attr("transform");
    const xTrans = xTranStr.substring(xTranStr.indexOf("(")+1, xTranStr.indexOf(")")).split(",");

    d3.select(container.current).append('text')
        .text('Genetisk diversitet (')
        .attr('x', x.range()[0] + (x.range()[1] - x.range()[0])/2.3)
        .attr('y', parseFloat(xTrans[1]) + xAxisHeight + 0.04*height)
        .style('text-anchor', 'middle')
        .append('tspan').text('cyt b').style('font-style', 'italic')
        .append('tspan').text(')').style('font-style', 'normal');

	// ADD Y TICKS AND LABELS
	const Yticks = d3.select(container.current).append('g').attr('id', 'yAxis')
		.attr('transform', 'translate(0,0)')
		.selectAll('yTicks')
    	.data(Array(7).fill().map((d, i) => 80 - i*20)) //data.map(function(d) { return d.Band; })
    
    Yticks.enter().append('line')
    	.attr('x1', 0 )
    	.attr('y1', d=> proj([0, d] )[1] )
    	.attr('x2', margin.left - 0.005*width )
    	.attr('y2', d=> proj([0, d] )[1] )
    	.attr('stroke', 'rgb(160, 160, 160)')
    	.attr('stroke-width', '0.03em')
    	.attr('stroke-dasharray', '0.2em,0.2em');

     Yticks.enter().append('text')
    	.attr('x', margin.left - 0.02*width )
    	.attr('y', d=> proj([0, d] )[1])
    	.attr('dy', function() {return d3.select(this).attr('height')} )
    	.text(d=> d +'°' )
    	.style('text-anchor', 'end')
    	.each(function(_,i){
		    if(i === 0) d3.select(this).remove();
		});

	//ADD Y TITLE
    d3.select(container.current).append('g').append('text')
        .text('Breddegrad')
        .attr('transform', 'translate('+ (margin.left-margin.left/1.3) + ',' + proj([0,1])[1]  + ')rotate(-90)')
        .style('text-anchor', 'middle');

	//PLOT BARS
	d3.select(container.current).append('g')
    	.selectAll('rect')
    	.data(data)
    	.join('rect')
    	.attr('x',  x(x.domain()[0]))
    	.attr('y', d=> proj([0, (parseInt(d.Band.split(' ')[0]))])[1] + 1.5  )
    	.attr('width',d => x(d.GD) - margin.left)
    	.attr('height', d => proj([0, parseInt(d.Band.split(' ')[0])-10 ])[1]  -  proj([0, parseInt(d.Band.split(' ')[0])])[1] - 2)
    	.style('fill', 'rgb(230, 230, 230)')
    	.style('stroke', 'rgb(0, 0, 0)')
    	.style('stroke-width', 0.5);

    //DEFINE COLOR GRADIENT FOR LINE
    const lineColorData = data.map(d=>d.GD).sort(d3.ascending);
    const lineColorGradient = lineColorData.map(function(d, i){
    	return {'offset': (d - d3.min(lineColorData)) / (d3.max(lineColorData) - d3.min(lineColorData)), 'color': colorScale(lineColorData[i])} 
    })

	d3.select(container.current).append('linearGradient')
    	.attr('id', 'line-gradient')
    	.attr('gradientUnits', 'userSpaceOnUse')
     	.attr('x1', x(d3.min(lineColorData)))
     	.attr('y1', 0)
     	.attr('x2', x.range()[1])
     	.attr('y2', 0)
     .selectAll('stop')
     	.data(lineColorGradient)
     	.join('stop')
     	.attr('offset', d => d.offset)
     	.attr('stop-color', d => d.color);

	//PLOT LINE
	d3.select(container.current).append('path')
		.datum(data)
		.attr('fill', 'none')
		.attr('stroke', 'url(#line-gradient)')
		.attr('stroke-width', '0.25em')
		.attr('d' , d3.line()
								.x(d=> x(d.GD))
								.y(d=> proj([0, (parseInt(d.Band.split(' ')[0]))-5])[1])
								.curve(d3.curveCatmullRom.alpha(1))

			)

};